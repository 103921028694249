import MenuIcon from "@mui/icons-material/Menu";
import MenuDialog from "./MenuDialog";
import { useState } from "react";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <header>
        <nav class="border-gray-200 bg-black">
          <div class="min-w-full justify-center hidden md:flex">
            <a class="flex items-center mt-4" href="/">
              <img src="/Logo.png" class="h-20" />
            </a>
              <div className="text-white absolute right-0 m-5 p-5 border-white border">
                Call us at +971509943228
              </div>
          </div>
          <div className="min-w-full place-content-between flex md:hidden pt-4">
            <a class=" ml-5" href="/">
              <img src="/Logo.png" class="h-14" />
            </a>

            <div
              class="mr-5 cursor-pointer hover:border p-2"
              onClick={() => setShowMenu(true)}
            >
              <MenuIcon sx={{ fontSize: 40, color: "white" }} />
            </div>
          </div>
          <hr class="border-gray-700 mt-4 mx-10" />
          <div class="hidden justify-between m-3 mx-14 md:flex">
            <a
              class="cursor-pointer text-[#8a9496] hover:text-[#adb9bc]"
              href="/WhyChooseUs"
            >
              Why choose us?
            </a>
            {/* <a class="cursor-pointer text-[#8a9496] hover:text-[#adb9bc]" href="/OurServices">
              Our Services
            </a> */}
            <a
              class="cursor-pointer text-[#8a9496] hover:text-[#adb9bc]"
              href="/AboutUs"
            >
              About Us
            </a>
            <a
              class="cursor-pointer text-[#8a9496] hover:text-[#adb9bc]"
              href="/Careers"
            >
              Careers
            </a>
            <a
              class="cursor-pointer text-[#8a9496] hover:text-[#adb9bc]"
              href="/ContactUs"
            >
              Contact Us
            </a>
          </div>
          <hr class="pb-4 border-gray-700 mx-10" />
          <div></div>
        </nav>
      </header>
      <MenuDialog show={showMenu} onClose={() => setShowMenu(false)} />
    </>
  );
}

export default Header;
