export default function OurServices({ content }) {
  return (
    <>
      <div class="min-h-[100vh] flex flex-col items-center text-black font-segoeUILight md:text-2xl text-center mt-20 mb-20 gap-10">
        <div class="CustomFont text-3xl md:text-5xl">
          Our Services
        </div>
        {content?.OurServicesElements?.map((x) => {
          return Element(x);
        })}
      </div>
    </>
  );
}

function Element(data) {
  var url = data.ImagePath;
  return (
    <>
      <div
        className="m-5 h-[70vh] w-[95vw] md:w-[50vw] text-white flex flex-col place-content-center rounded-xl shadow-2xl"
        style={{ backgroundImage: `url(${url})`, backgroundSize: "cover" }}
      >
        <div className="bg-black bg-opacity-70 m-5 p-5 rounded-md">
          <div className="CustomFont md:text-2xl text-xl mb-5">
            {data.Title}
          </div>
          <div className="text-justify text-xl">{data.Description}</div>
        </div>
      </div>
    </>
  );
}
