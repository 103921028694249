export default function Careers({ content }) {
  return (
    <>
      <div className="min-h-[100vh] flex flex-col items-center text-black font-segoeUILight md:text-2xl text-center mt-20 mb-20 gap-20">
        <div class="CustomFont  text-3xl md:text-5xl text-center">Careers</div>

        <img
          className="md:w-[30vw] w-[70vw] rounded-lg shadow-2xl "
          src="/Images/resumes.jpeg"
        />
        <div className=" mt-8 text-black text-center ">
          <div className="font-segoeUI font-semibold">
            Join Our Talent Pool!
          </div>
          <div className="md:mx-96 text-xl text-justify mx-5">
            While we currently have no openings, we’re always eager to connect
            with passionate individuals interested in logistics. Shoot your
            resume to ops@hamzaarcargo.com
          </div>
        </div>
      </div>
    </>
  );
}
