import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Button, TextField } from "@mui/material";

export default function ContactUs({ content }) {
  return (
    <>
      <div className="min-h-[100vh] flex flex-col items-center text-black font-segoeUILight md:text-2xl text-center mt-20 mb-20 gap-20">

        <div class="CustomFont  text-3xl md:text-5xl">
          Contact Us
        </div>

        <div className="flex flex-col gap-10 border border-black p-10">
          <div className="flex items-center justify-center gap-10">
            <SmartphoneIcon sx={{ fontSize: 50 }} />
            <div>+971509943228</div>
          </div>

          <div className="flex items-center justify-center gap-10">
            <PhoneIcon sx={{ fontSize: 50 }} />
            <div>+97167442875</div>
          </div>

          <div className="flex items-center justify-center gap-10">
            <EmailIcon sx={{ fontSize: 50 }} />
            <div>ops@hamzaarcargo.com</div>
          </div>
        </div>

        <div>-Or-</div>

        <div className="flex flex-col gap-10 w-[90vw] md:w-[40vw]">
          <TextField label="Name" />
          <TextField label="Email or Phone" />
          <TextField
            label="Message"
            multiline
            rows={4}
          />
          <div className="flex justify-end">
            <Button variant="contained" >Send</Button>
          </div>
        </div>
      </div>
    </>
  );
}
