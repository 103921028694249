import LocationOnIcon from "@mui/icons-material/LocationOn";

function Footer() {
  return (
    <>
      <footer class="shadow bg-black bottom-0 min-w-full text-white">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <a
              href="/"
              class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <img src="/Logo.png" alt="Logo" class="h-20" />
            </a>
            <div class="mr-20 flex flex-row gap-10">
              <div class="flex flex-row gap-2">
                <LocationOnIcon />
                <div>
                  <div>Q1-07-018</div>
                  <div>B Saif Zone</div>
                  <div>Sharjah</div>
                  <div>UAE</div>
                </div>
              </div>

              <div class="flex flex-row gap-2">
                <LocationOnIcon />
                <div>
                  <div>Dubai,</div>
                  <div>UAE</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-6 sm:mx-auto border-gray-700 lg:my-8" />
          <span class="block text-sm sm:text-center text-gray-400">
            © 2024{" "}
            <a href="https://flowbite.com/" class="hover:underline">
              HamZaar™
            </a>
            . All Rights Reserved.
          </span>
          <span class="block text-xs sm:text-right text-gray-700">
            <a href="https://www.freepik.com/free-photo/cargo-delivery-vehicle_34554356.htm#query=air%20cargo%20shipping&position=0&from_view=keyword&track=ais_hybrid&uuid=e6629368-1587-4797-8738-14edbfbf06dd">
              Image by kjpargeter
            </a>{" "}
            on Freepik
          </span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
