import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class DemoCarousel extends Component {
  render() {
    return (
      <div className="slider-container">
        <Carousel
          autoPlay={true}
          dynamicHeight={false}
          swipeable={false}
          infiniteLoop={true}
          interval={2000}
          showStatus={false}
          showThumbs={false}
        >
          {this.props.items.map((item, index) => (
            <div key={index}>
              <img src={item.image} style={{ height:'80vh', objectFit:'cover' }}/>
              <p className="legend">{item.text}</p>
            </div>
          ))}
        </Carousel>
      </div>
    );
  }
}

export default DemoCarousel;
