import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

export default function MenuDialog(props) {
  return (
    <>
      <Dialog open={props.show} onClose={props.onClose}>
        <DialogContent sx={{ backgroundColor: "black", opacity: 1 }}>
          <div class="flex flex-col gap-5">
            <Button href="/WhyChooseUs" sx={{color:"white"}}>Why choose Us?</Button>
            {/* <Button href="/OurServices" sx={{color:"white"}}>Our Services</Button> */}
            <Button href="/AboutUs" sx={{color:"white"}}>About Us</Button>
            <Button href="/Careers" sx={{color:"white"}}>Careers</Button>
            <Button href="/ContactUs" sx={{color:"white"}}>Contact Us</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
