import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import content from "./jsons/Content.json";
import WhyChooseUs from "./pages/WhyChooseUs";
import OurServices from "./pages/OurServices";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {

  return (
    <>
      <CssBaseline />
      <Header />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home content={content} />} />
          <Route
            exact
            path="/WhyChooseUs"
            element={<WhyChooseUs content={content} />}
          />
          <Route
            exact
            path="/OurServices"
            element={<OurServices content={content} />}
          />
          <Route
            exact
            path="/AboutUs"
            element={<AboutUs content={content} />}
          />
          <Route
            exact
            path="/Careers"
            element={<Careers content={content} />}
          />
          <Route
            exact
            path="/ContactUs"
            element={<ContactUs content={content} />}
          />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
