export default function WhyChooseUs({ content }) {
  return (
    <>
      <div class="min-h-[100vh] flex flex-col items-center text-black font-segoeUILight md:text-2xl text-center mt-20 mb-20 gap-20">
        <div class="CustomFont text-3xl md:text-5xl">Why choose Hamzaar Cargo?</div>
        {content?.WhyChooseUsElements?.map((x, i) => {
          return (
            <>
              <div class="m-5 w-[95vw] md:w-[50vw]">
                {i % 2 == 0 ? EvenElement(x) : OddElement(x)}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

function OddElement(data) {
  var imageUrl = data.ImagePath;
  return (
    <>
      <div class="flex flex-row items-center gap-5">
        <div class="text-justify">{data.Description}</div>
        <img src={imageUrl} class="w-[20vw] md:w-max" />
      </div>
    </>
  );
}

function EvenElement(data) {
    var imageUrl = data.ImagePath;
  return (
    <>
     <div class="flex flex-row items-center gap-5">
        <img src={imageUrl} class="w-[20vw] md:w-max" />
        <div class="text-justify">{data.Description}</div>
      </div>
    </>
  );
}
