export default function AboutUs({ content }) {
  var topUrl = content?.AboutUs?.TopImagePath;
  var bottomUrl = content?.AboutUs?.BottomImagePath;
  return (
    <>
      <div className="min-h-[100vh] flex flex-col justify-between">
        <img src={topUrl} />
        <div className="text-center flex justify-center mt-20 mb-20 text-black">
          <div className="flex flex-col">
            <div class="CustomFont text-3xl md:text-5xl">
              About Us
            </div>
            <div className="text-justify font-segoeUILight md:text-2xl flex m-5 w-[95vw] md:w-[50vw]">
              {content?.AboutUs?.Description}
            </div>
          </div>
        </div>
        <img src={bottomUrl} />
      </div>
    </>
  );
}
