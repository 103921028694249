import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";
import DemoCarousel from "../components/DemoCarousel";

const baseUrl = process.env.REACT_APP_API_URL;

export default function Home({ content }) {
  return (
    <>
      <div class="min-h-[100vh]">
        <div class="min-w-full bg-black flex place-content-center align-middle">
          <div className="w-full">
            <DemoCarousel items={content.CarouselItems}/>
          </div>
        </div>

        <div class="m-5">
          {content?.OurServicesElements?.map((x) => {
            return <HomePageItem item={x} />;
          })}
        </div>
      </div>
    </>
  );
}

const HomePageItem = ({ item }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
  });
  //md:text-5xl text-xl
  var hasImage = item.ImagePath != null;
  return (
    <>
      <div className="place-content-center align-middle grid">
        <>
          <div className={inView ? "" : "hidden"}>
            <img src="/container_top.png" className="w-[95vw] md:w-[50vw]" />

            <div className="content container-animation">
              <div class="flex justify-center w-[95vw] md:w-[50vw] px-2 md:px-10">
                <div className="rounded-md text-center mx-1 md:mx-5 p-5 bg-black bg-opacity-70">
                  <div className="CustomFont container-animation2 mb-8 text-white">
                    {item.Title}
                  </div>
                  <div className="text-justify font-segoeUILight text-white">
                    {item.Description}
                  </div>
                </div>
              </div>
            </div>

            <img src="/container_bottom.png" className="w-[95vw] md:w-[50vw]" />
          </div>
        </>

        <img
          className={
            inView ? "hidden w-[95vw] md:w-[50vw]" : "w-[95vw] md:w-[50vw]"
          }
          src="/container.png"
        />
      </div>

      {!inView && (
        <div style={{ position: "absolute", padding: 200 }}>
          <div ref={ref}></div>
        </div>
      )}
    </>
  );
};
